.etFirstCol{
    background-image: url(../assets/h1-banner1.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: scroll;
    /* justify-content: center; */
    /* align-items: center; */
    
    /* align-content: center; */
    /* min-height: 600px; */
    
}
.etSecondCol{
    background-image: url(../assets/h1-mapbg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    padding: 5%;
    /* justify-content: center; */
    /* align-items: center; */
    
    /* align-content: center; */
    /* min-height: 600px; */
}

.circleDiv{
   
}

.divForCounter{
   background-color: #daa14c;
   padding: 5%;
}

@media (min-width: 0px) and (max-width: 500px) {
}



