.headerNav{
    padding: 5% 0%;
    }
    .firstNav{
    /* width: 50%; */
    }
    .secondNav{
        padding-right: 5%;
        }
    .imgNav{
        width: 50%;
        height: auto;
        padding: 0%;
    }

    .headerButton{
        border: 0;
        border-radius: 0;
        background-color: #daa14c;
        padding: 7% 10% 7% 10%;
        font-size: 0.9em;
        font-weight: bold;
        color: white;
    }
    
    @media (min-width: 0px) and (max-width: 500px) {
        .headerNav{
            padding: 0% 0%;
        }
        .firstNav{
            width: 70%;
        }
        .imgNav{
            width: 100%;
            height: auto;
        }
    }