.wsRow{
    background-image: url(../assets/h1-frame.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 95%;
    background-attachment: scroll;
    padding: 4% 2%;
    background-color: #ededed;
    /* min-height: 600px; */
}
.wcta_number{
    -webkit-text-stroke: 2px rgba(0,0,0,.1);
    font-size: 10em;
    line-height: 1;
    font-weight: 800;
    color: transparent;
    text-align: left;
    font-family: 'Plus Jakarta Sans','HelveticaNeue-Light','Helvetica Neue Light','Helvetica Neue','Helvetica','Arial','Lucida Grande','sans-serif';

}

@media (min-width: 0px) and (max-width: 500px) {
    .wsRow{
        
        background-size: contain;
        
    }
}