.faqRow{
    background-color: #fff;
    padding: 7% 2.5% 7% 2.5%;
}
.faqFirstCol{

}
.faqFirstSecond{
    
}
.faqImg{
    width: auto;
    height: auto;
}

.accordion-header {
    font-size: 240px;
  }

  .headingTitle{
    font-size: 2.3em;
    line-height: 1.0em;
    font-weight: 700;
    color: #30373e;
    letter-spacing: -1px;
    font-family: 'Plus Jakarta Sans','HelveticaNeue-Light','Helvetica Neue Light','Helvetica Neue','Helvetica','Arial','Lucida Grande','sans-serif';
    margin-bottom: 3%;
  }

  @media (min-width: 0px) and (max-width: 500px) {
    .headingTitle{
        font-size: 2.3em;
        line-height: 1.0em;
        text-align: center;
        font-weight: 700;
        color: #30373e;
        letter-spacing: -1px;
        font-family: 'Plus Jakarta Sans','HelveticaNeue-Light','Helvetica Neue Light','Helvetica Neue','Helvetica','Arial','Lucida Grande','sans-serif';
        margin-bottom: 5%;
        margin-top: 5%;
      }
      .faqImg{
        width: 100%;
        height: auto;
    }
}
