.ssRow{
    background-image: url(../assets/About_01_imges.png);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 34%;
    background-attachment: scroll;
    /* min-height: 600px; */
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-top: 5%;
    padding-bottom: 5%;
}
.ssColFirst{
    background-image: url(../assets/About_02.jpg);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 82% 95%;
    background-attachment: scroll;
    
    /* min-height: 600px; */

}
.ssColSecond{
    background-color: white;
}
.firstImg{
   width: 100%;
   height: auto;
   padding-top: 10%;
   padding-left: 10%;
}
.marTop{
    margin-top: 5%;
}
.ssSecondRow{

    background-color: white;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0% 2% 4% 2%;
}


@media (min-width: 0px) and (max-width: 500px) {
    .ssRow{
        background-image: none;
        
        /* min-height: 600px; */
        padding: 3%;
    }
    .ssColFirst{
        background: none;
    }
    .firstImg{
        width: 100%;
        height: auto;
        padding-top: 0%;
        padding-left: 0%;
     }

}
