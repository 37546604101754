.frsRow{
    background-image: url(../assets/h1-bg02.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    /* justify-content: center; */
    /* align-items: center; */
    padding: 8% 1%;
    /* align-content: center; */
    min-height: 600px;
}

.sliderImages{
    width: 90%;
    height: 400px;
}

@media (min-width: 0px) and (max-width: 500px) {
.sliderImages{
    width: 100%;
    height: 300px;
}
}