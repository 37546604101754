.heading-paragraph{
    font-size: 1em;
    text-transform: uppercase;
    line-height: 2em;
    letter-spacing: 0.2em;
   color: #daa14c;
   font-family: 'Plus Jakarta Sans','HelveticaNeue-Light','Helvetica Neue Light','Helvetica Neue','Helvetica','Arial','Lucida Grande','sans-serif';
   margin-bottom: 5%;
}

.heading-title{
  font-size: 4em;
    line-height: 1.0em;
    font-weight: 700;
    color: #30373e;
    letter-spacing: -1px;
    font-family: 'Plus Jakarta Sans','HelveticaNeue-Light','Helvetica Neue Light','Helvetica Neue','Helvetica','Arial','Lucida Grande','sans-serif';
    margin-bottom: 5%;
}
.heading-title-light{
    font-size: 4em;
      line-height: 1.0em;
      font-weight: 700;
      color: #fff;
      letter-spacing: -1px;
      font-family: 'Plus Jakarta Sans','HelveticaNeue-Light','Helvetica Neue Light','Helvetica Neue','Helvetica','Arial','Lucida Grande','sans-serif';
      margin-bottom: 5%;
  }
.paragraph-16{
  font-size: 1.3em;
    line-height: 1.5em;
    color: #535353;
    font-family: 'Plus Jakarta Sans','HelveticaNeue-Light','Helvetica Neue Light','Helvetica Neue','Helvetica','Arial','Lucida Grande','sans-serif';
    margin-bottom: 5%;
}
.paragraph-16-light{
    font-size: 1.3em;
      line-height: 1.5em;
      color: #fff;
      font-family: 'Plus Jakarta Sans','HelveticaNeue-Light','Helvetica Neue Light','Helvetica Neue','Helvetica','Arial','Lucida Grande','sans-serif';
      margin-bottom: 5%;
  }

.main-buton{
    font-size: 1.0em;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 2em;
    letter-spacing: 0.2em;
    background-color: #30373e;
    border-radius: 30px;
    padding: 13px 38px 13px 40px;
    border: none;
    margin-top: 5%;
}
.main-buton:hover{
    background-color: #daa14c; 
    
}
.main-buton-light{
    color: #30373e;
    font-size: 1.0em;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 2em;
    letter-spacing: 0.2em;
    background-color: #fff;
    border-radius: 30px;
    padding: 13px 38px 13px 40px;
    border: none;
    margin-top: 5%;
}
.main-buton-light:hover{
    background-color: #daa14c; 
    
}
.main-buton-brown{
    color: #fff;
    font-size: 1.0em;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 2em;
    letter-spacing: 0.2em;
    background-color: #daa14c;
    border-radius: 30px;
    padding: 13px 38px 13px 40px;
    border: none;
    margin-top: 5%;
}
.main-buton-brown:hover{
    background-color: #daa14c; 
    
}

.icon-box-image{
    width: 80%;
    height: auto;
    
}
.icon-box-title{
    font-size: 1.4em;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 2em;
    letter-spacing: -.3px;
    color: #30373e;
    font-family: 'Plus Jakarta Sans','HelveticaNeue-Light','Helvetica Neue Light','Helvetica Neue','Helvetica','Arial','Lucida Grande','sans-serif';
}

.icon-box-description{
    font-size: 1.1em;
    line-height: 1.5em;
    color: #535353;
    font-family: 'Plus Jakarta Sans','HelveticaNeue-Light','Helvetica Neue Light','Helvetica Neue','Helvetica','Arial','Lucida Grande','sans-serif';

}


@media (min-width: 0px) and (max-width: 500px) {
    .heading-paragraph{
        text-align: center;
    }
    
    .heading-title{
        text-align: center;
        font-size: 2.0em;
              }
    .heading-title-light{
    text-align: center;
    font-size: 2.0em;  
            }
    .paragraph-16{
        text-align: center;  
      }
      .paragraph-16-light{
        text-align: center;  
      }

      .main-buton{
        font-size: 0.9em;
  
    }
    .main-buton-light{
        font-size: 0.9em;

    }
    .main-buton-brown{
        font-size: 0.9em;

    }

      .icon-box-image{
        align-self: center;
        width: 23%;
        height: auto;
      }
      .icon-box-title{
        text-align: center; 
      }

        .icon-box-description{
            text-align: center; 
        }

}
