.FormSection{
background-color: #fff;
padding: 10% 9%;
border-radius: 5%;
}

@media (min-width: 0px) and (max-width: 500px) {
    .FormSection{
        padding: 10% 7%;
    }
}
