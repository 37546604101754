.footRow{
    background-image: url(../assets/footer_BG_01.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    /* justify-content: center; */
    /* align-items: center; */
    padding: 7% 0% 1% 0%;
    /* align-content: center; */
    /* min-height: 600px; */
    
}

@media (min-width: 0px) and (max-width: 500px) {
    .footRow{
 
        padding: 15% 0% 2% 0%;
        
    }
}