.firstRow{
  background-image: linear-gradient(to right, rgba( 0, 0, 0, 0.9), rgba( 0, 0, 0, 0.5)), url(../assets/Home-3.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  /* justify-content: center; */
  /* align-items: center; */
  
  /* align-content: center; */
  /* min-height: 600px; */
  padding: 8% 2%;
}

.firstCol{

}
.firstCol2{
  /* background-image: url(../assets/About_09.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll;  */
  background-color: transparent;
}




@media (min-width: 0px) and (max-width: 500px) {
}



