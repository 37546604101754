.ninRow{
    background-image: url(../assets/About_BG_01.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    /* justify-content: center; */
    /* align-items: center; */
    padding: 10% 2.5%;
    /* align-content: center; */
    min-height: 600px;
}

.countNinth{
color: #daa14c;
font-size: 4em;
font-weight: bold;

}
.countText{
    color:#fff;
    font-size: 1.4em;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 2em;
    letter-spacing: -.3px;
 
    font-family: 'Plus Jakarta Sans','HelveticaNeue-Light','Helvetica Neue Light','Helvetica Neue','Helvetica','Arial','Lucida Grande','sans-serif';
}

.boderBtoom{
    border-bottom: solid 1px rgba(255,255,255,.3) ;
}
.boderBtoom2{
    border-bottom: none ;
}
.borderVertical{
    border-right: solid 1px rgba(255,255,255,.3) ;
}

.imgForNin{
    width: 50%;
    height: auto;
}


@media (min-width: 0px) and (max-width: 500px) {

    .countNinth{

    }
    .countText{
        text-align: center; 
      }
      .boderBtoom2{
        border-bottom: solid 1px rgba(255,255,255,.3) ;
    }
      .borderVertical{
        border-right: none ;
    }
    .imgForNin{
        width: 100%;
        height: auto;
    }
}