.sevtsRow{
    /* background-image: url(../assets/h1-bg03.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll; */
    background-color: #fff;
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-top: 7%;
    padding-bottom: 7%;
}
.sxsImg{
    width: 100%;
    height: auto;
}

.box-shadow{
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease-in-out;
}



@media (min-width: 0px) and (max-width: 500px) {
    .ftsImg{
        width: 55%;
        height: auto;
        
    }
    .ftsRow{
        /* background-image: none; */
        
        /* min-height: 600px; */
        /* padding: 3%; */
    }

}