.stsRow{
    background-image: url(../assets/About_BG_02.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    /* justify-content: center; */
    /* align-items: center; */
    
    /* align-content: center; */
    /* min-height: 600px; */
    padding: 8% 2%;
}

.stsCol{

}
.stsCol2{
    /* background-image: url(../assets/About_09.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: scroll;  */
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 6%;
    padding-right: 7%;
}




@media (min-width: 0px) and (max-width: 500px) {
    .stsCol2{
        padding-top: 2%;
    padding-right: 1%; 
    }
}



